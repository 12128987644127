import React from "react";
// import Usermenu from "../../components/UserMenu/Usermenu";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Header from "../../components/Header/Header";

const PageNotFound = () => {
  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row">
      {" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <div className="flex flex-col justify-center items-center   max-w-7xl w-full pl-5 pr-5 gap-5 sm:ml-72 lg:ml-80">
        <div className="h-8 w-full sm:h-16 "> </div>
        <div>404 not forund</div>
      </div>
    </div>
  );
};

export default PageNotFound;
