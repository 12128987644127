import React from "react";
import { SyncOutlined } from "@ant-design/icons";
import "./MainButton.css";

const MainButton = (props) => {
  const { onClick, loading, disabled } = props;
  return (
    <button
      className="mainBtn"
      style={loading ? { opacity: "0.9" } : {}}
      onClick={onClick}
      disabled={disabled}
    >
      {props.children} {loading && <SyncOutlined spin />}
    </button>
  );
};

export default MainButton;
