export default function getLimitForPlan(plan) {
  switch (plan) {
    case "free":
      return 60;

    case "starter":
      return 180;

    case "pro":
      return 420;

    case "max":
      return 1000;

    default:
      break;
  }
}
