export default function convertSecondsToHMSM(sec_num, type = "dot") {
  // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  let milliseconds = Math.floor((seconds - Math.floor(seconds)) * 1000)
    .toString()
    .padStart(3, "0");
  seconds = Math.floor(seconds);

  if (type === "dot") {
    let timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}.${milliseconds}`;
    return timeString;
  } else {
    let timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")},${milliseconds}`;
    return timeString;
  }
}
