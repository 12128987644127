import { Modal } from "antd";
import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton.js/SecondaryButton";

const Confirm = ({
  description,
  open,
  setopen,
  cancelFunction,
  okFunction,
  okText,
}) => {
  return (
    <Modal
      open={open}
      // onOk={handleOk}
      onCancel={() => {
        cancelFunction();
        setopen(false);
      }}
      footer={[
        <PrimaryButton
          onClick={() => {
            cancelFunction();
            setopen(false);
          }}
        >
          {" "}
          Cancel
        </PrimaryButton>,

        <span className=""> {`      `}</span>,

        <SecondaryButton
          danger={okText.includes("Delete")}
          onClick={() => {
            okFunction();
            setopen(false);
          }}
        >
          {okText}
        </SecondaryButton>,
      ]}
    >
      <div className=" flex flex-col items-center gap-7">
        <div className="mt-7  text-lg ">
          {/* Do you want to reset subtitles to its original state, all your changes
          will be lost. */}

          {description}
        </div>
      </div>
    </Modal>
  );
};

export default Confirm;
