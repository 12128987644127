import React from "react";
// import ring from "../../assets/shapes/ring.svg";
import "./BasicLoader.css";
const BasicLoader = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center basicloader">
      {/* <div>loading... </div> */}
      <span className="loader"></span>
      <div className="w-20"> {/* <img alt="loader" src={ring} /> */}</div>
    </div>
  );
};

export default BasicLoader;
