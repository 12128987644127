import { Button } from "antd";
import React from "react";

const PrimaryButton = (props) => {
  const { onClick, loading, disabled } = props;
  return (
    <Button
      disabled={disabled}
      style={{
        backgroundColor: "black",
        minWidth: "115px",
        color: disabled ? "white" : "",
        opacity: disabled ? "0.7" : "",
      }}
      type="primary"
      loading={loading}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
