import { addDoc, collection, onSnapshot } from "@firebase/firestore";
import { db } from "../firebase";
// import initializeStripe from "./initializeStripe";
const getPriceCode = (plan) => {
  switch (plan) {
    case "starterMonthly":
      return "price_1N3Zv7KXp0WQVPyud64XNUmm";
    case "proMonthly":
      return "price_1N3ZvFKXp0WQVPyucH6eaoD3";
    case "maxMonthly":
      return "price_1N3ZvKKXp0WQVPyueLxBIqI5";
    case "starterYearly":
      return "price_1N3ZvQKXp0WQVPyuHxLUAPPw";
    case "proYearly":
      return "price_1N3ZvWKXp0WQVPyucvkltvmU";
    case "maxYearly":
      return "price_1N3ZvdKXp0WQVPyu1AC0CEhl";

    default:
      return "price_1N3Zv7KXp0WQVPyud64XNUmm";
  }
};
const createCheckoutSession = (uid, plan) => {
  const q = collection(db, "users", uid, "checkout_sessions");

  addDoc(q, {
    price: getPriceCode(plan),
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  })
    .then((doc) => {
      onSnapshot(doc, async (snap) => {
      if (snap.exists()) {
        const { sessionId } = snap.data();

        if (sessionId) {
          // const stripe = await initializeStripe();
          // stripe.redirectToCheckout({ sessionId });
          window.location.href = snap.data().url;
        }
      } else {
        console.log("failed to create a checkout session");
      }
      });
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export default createCheckoutSession;
