import React, { useContext } from "react";
import logo from "../../assets/logo/logo.svg";
import googlesvg from "../../assets/logo/googlesignin.svg";
import facebooksvg from "../../assets/logo/facebook.svg";
import {
  // signInWithPopup,
  GoogleAuthProvider,
  // browserLocalPersistence,
  // setPersistence,
  FacebookAuthProvider,
  signInWithRedirect,
  // signInWithCredential,
} from "firebase/auth";
// import { FacebookAuthProvider } from "firebase/auth"

import { auth } from "../../firebase";
import { AppMessage } from "../../App";
// import { useNavigate } from "react-router-dom/dist";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});

const facebookprovider = new FacebookAuthProvider();
facebookprovider.setCustomParameters({
  prompt: "select_account",
});

const Login = () => {
  // const navigate = useNavigate();
  const messageApi = useContext(AppMessage);
  return (
    <div className="w-full h-screen ">
      <div className="bg-primary flex flex-col items-center  gap-10 h-full w-full pt-10">
        <div className="flex flex-col items-center justify-around gap-7  ">
          <div className="flex-shrink-0 w-3/4 ">
            <img className="  w-full " src={logo} alt="Your Company" />
          </div>
          <div className="text-white text-3xl text-center pl-4 pr-4 font-bold">
            Add Subtitles to your videos with ease
          </div>{" "}
        </div>
        <div className="   bg-white rounded-xl  flex flex-col items-center gap-3 shadow p-10 m-3">
          <h1 className="text-xl text-center ">
            Sign in to start using videosubtitles
          </h1>

          <button
            onClick={async () => {
              try {
                await signInWithRedirect(auth, provider);
              } catch (error) {
                messageApi.open({
                  type: "error",
                  content: error.message,
                  duration: 5,
                });
              }
            }}
            className=" border flex p-px items-center border-slate-200 rounded text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150 bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50"
          >
            <img
              className="w-10 h-10"
              src={googlesvg}
              loading="lazy"
              alt="google logo"
            />
            <span className="text-white p-2 font-semibold font-sans ">
              {" "}
              Sign in with Google
            </span>
          </button>
          {/* <button
            onClick={async () => {
              try {
                await signInWithRedirect(auth, facebookprovider);
              } catch (error) {
                messageApi.open({
                  type: "error",
                  content: error.message,
                  duration: 5,
                });
              }
            }}
            className=" border flex pl-2 items-center border-slate-200 rounded text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150 bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50"
          >
            <img
              className="w-7 h-7"
              src={facebooksvg}
              loading="lazy"
              alt="facebook logo"
            />
            <span className="text-white p-2 font-semibold font-sans ">
              {" "}
              Login with Facebook
            </span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
// {/* <div className="flex-shrink-0 sm:hidden">
//   <img className="h-8  w-64 " src={logo} alt="Your Company" />
// </div>
// <div className="text-white text-3xl text-center pl-4 pr-4 sm:hidden">
//   Add Subtitles to your videos with ease
// </div>{" "}
// <div className=" h-80  bg-slate-100 rounded-3xl  flex flex-col items-center shadow p-5">
//   <h1 className="text-3xl font-extrabold">Get Started..</h1>
// </div> */}
