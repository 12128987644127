import {
  // ArrowSmallUpIcon,
  // ScissorsIcon,
  // XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import convertSecondsToHMSM from "../../Util/convertSecondsToHMSM";
import convertSecondsToHMS from "../../Util/convertSecondsToHMS";
import Confirm from "../Confirm/Confirm";

const SubtitleField = ({
  key,
  start,
  end,
  text,
  active,
  totalcues,
  position,
  startLimit,
  endLimit,
  handleSubtitleTimeChange,
  handleSubtitleTextChange,
  deleteSubtitle,
}) => {
  const [Start, setStart] = useState(start);
  const [End, setEnd] = useState(end);
  const [Text, setText] = useState(text);
  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    setStart(start);
    setEnd(end);
  }, [start, end]);
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     // Update the state after 3 milliseconds.
  //     handleSubtitleTextChange(position - 1, Text);
  //   }, 3000);

  //   // Clear the timeout when the component unmounts or when the Text state changes.
  //   return () => clearTimeout(timeoutId);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [Text]);
  return (
    <div
      className="flex flex-col items-center w-full pl-2 pr-2 "
      key={position}
    >
      <Confirm
        description={`Do you want to delete the subtitle number ${position}?`}
        open={openDelete}
        setopen={setOpenDelete}
        cancelFunction={() => {
          console.log("delete canceled");
        }}
        okFunction={() => {
          deleteSubtitle(position - 1);
        }}
        okText={"Delete"}
      />
      <div className="flex w-full justify-between">
        <div className="text-gray-600 text-sm italic">
          {position + "/" + totalcues}
        </div>{" "}
        {/* <div>
          start:
          <span className="text-gray-600 text-sm italic">
            {convertSecondsToHMS(start)}
          </span>
          ---end:
          <span className="text-gray-600 text-sm italic">
            {convertSecondsToHMS(end)}
          </span>
        </div> */}
        <div className="text-gray-600 text-sm italic">
          {convertSecondsToHMS(start)}
          ---
          {` `}
          {convertSecondsToHMS(end)}
        </div>
        <div className="flex gap-2">
          <XMarkIcon
            className="w-7 text-red-500 cursor-pointer"
            onClick={() => {
              setOpenDelete(true);
            }}
          />
        </div>
      </div>
      <div className="text-black flex justify-between w-full ">
        {/* <div className="flex gap-2"> */}
        {/* <input className="w-10" /> {"-->"}
          <input className="w-10" /> */}

        <Slider
          range
          // defaultValue={[start, end]}
          value={[Start, End]}
          min={startLimit}
          max={endLimit}
          step={0.1}
          className="w-full"
          onAfterChange={([newStart, newEnd]) => {
            let s = newStart;
            let e = newEnd;
            // if (newStart < newEnd) {
            //   s = e;
            // }
            handleSubtitleTimeChange(position - 1, s, e);

            // Handle the onChange event of the slider
            // and update the start and end times of the subtitle cue
          }}
          onChange={([newStart, newEnd]) => {
            let s = newStart;
            let e = newEnd;
            // if (newStart < newEnd) {
            //   s = e;
            // }
            // handleSubtitleTimeChange(position - 1, s, e);
            setStart(s);
            setEnd(e);

            // Handle the onChange event of the slider
            // and update the start and end times of the subtitle cue
          }}
          tooltip={{
            // open: true,
            formatter: (value) => {
              return convertSecondsToHMSM(value);
            },
          }}
        />
        {/* </div> */}
      </div>
      <textarea
        className={` border border-black rounded-lg w-full p-4 ${
          active ? "bg-gray-800 text-white" : ""
        }`}
        value={Text}
        onBlur={(e) => {
          handleSubtitleTextChange(position - 1, e.target.value);
        }}
        maxLength={150}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    </div>
  );
};

export default SubtitleField;
