import React, { useContext, useEffect, useState } from "react";


import { Skeleton, Switch } from "antd";
import SinglePrice from "../../components/SinglePrice/SinglePrice";
import starterIcon from "../../assets/shapes/starter-icon.png";
import proIcon from "../../assets/shapes/pro-icon.png";
import maxIcon from "../../assets/shapes/max-icon.png";
import freeicon from "../../assets/shapes/star-solid-svgrepo-com.svg";
import "./Upgrade.css";
import { auth, db } from "../../firebase";
// import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonInput from "antd/es/skeleton/Input";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { UserContext } from "../../App";

import SkeletonNode from "antd/es/skeleton/Node";
// import createCheckoutSession from "../../stripe/createCheckoutSession";

const monthlyFeatures = [
  {
    name: "VideoSubtitles Free Subscription",
    plan: "free",
    price: "0",
    icon: freeicon,
    // previousPrice: "12",
    currency: "USD",
    // offPercent: "20",
    forMessage: "Try it for free",

    chargeMessage: "No charges",
    features: ["60 credits only", "1 GB storage", "Max upload file size 500MB"],
    featuresx: [],
    // link: createCheckoutSession(user.id, "starterMonthly"),
  },
  {
    name: "VideoSubtitles Starter Monthly Subscription",
    plan: "starter",
    price: "9.99",
    icon: starterIcon,
    previousPrice: "12",
    currency: "USD",
    offPercent: "20",
    forMessage: "Try it for free",

    chargeMessage: "Billed per month",
    features: [
      "180 credits per month",
      "2 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "starterMonthly"),
  },
  {
    name: "VideoSubtitles Pro Monthly Subscription",
    plan: "pro",
    icon: proIcon,
    price: "19.99",
    previousPrice: "25",
    currency: "USD",
    offPercent: "20",
    forMessage: "Try it for free",

    chargeMessage: "Billed per month",
    features: [
      "420 credits per month",
      "5 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "proMonthly"),
  },
  {
    name: "VideoSubtitles Max Monthly Subscription",
    plan: "max",
    price: "39.99",
    icon: maxIcon,
    previousPrice: "50",
    currency: "USD",
    offPercent: "20",
    forMessage: "Try it for free",
    chargeMessage: "Billed per month",
    features: [
      "1000 credits per month",
      "10 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "maxMonthly"),
  },
];
const yearlyFeatures = [
  {
    name: "VideoSubtitles Free Subscription",
    plan: "free",
    price: "0",
    icon: freeicon,
    // previousPrice: "12",
    currency: "USD",
    // offPercent: "20",
    forMessage: "Try it for free",

    chargeMessage: "No charges",
    features: ["60 credits only", "1 GB storage", "Max upload file size 500MB"],
    featuresx: [],
    // link: createCheckoutSession(user.id, "starterMonthly"),
  },
  {
    name: "VideoSubtitles Starter Yearly Subscription",
    plan: "starter",
    icon: starterIcon,
    price: "99.90",
    previousPrice: "144",
    currency: "USD",
    offPercent: "30",
    forMessage: "Try it for free",

    chargeMessage: "Billed annually",
    features: [
      "180 credits per month",
      "2 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "maxMonthly"),
  },
  {
    name: "VideoSubtitles Pro Yearly Subscription",
    plan: "pro",
    price: "199.90",
    icon: proIcon,
    previousPrice: "300",
    currency: "USD",
    offPercent: "30",
    forMessage: "Try it for free",

    chargeMessage: "Billed annually",
    features: [
      "420 credits per month",
      "5 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "maxMonthly"),
  },
  {
    name: "VideoSubtitles Max Yearly Subscription",
    plan: "max",
    price: "399.90",
    icon: maxIcon,
    previousPrice: "600",
    currency: "USD",
    offPercent: "30",
    forMessage: "Try it for free",
    chargeMessage: "Billed annually",
    features: [
      "1000 credits per month",
      "10 GB storage",
      "Max upload file size 1GB",
    ],
    featuresx: [],
    // link: createCheckoutSession(user.id, "maxMonthly"),
  },
];

const Upgrade = () => {
  const [toggleYearly, setToggleYearly] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [activeProduct, setActiveProduct] = useState({ name: "" });
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    let unsub = () => {};
    const getRole = async () => {
      setLoading(true);

      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      decodedToken?.claims?.stripeRole
        ? setUserSubscription(decodedToken?.claims?.stripeRole)
        : setUserSubscription(null);
      console.log(decodedToken?.claims?.stripeRole);

      const docref = query(
        collection(db, "users", user.uid, "subscriptions"),
        where("status", "in", ["trialing", "active"]),
        orderBy("created", "desc")
      );
      unsub = onSnapshot(docref, (snapshot) => {
        setLoading(true);
        if (snapshot.empty) {
          // Show products

          setActiveProduct({ name: "VideoSubtitles Free Subscription" });

          setLoading(false);

          return;
        }
        console.log(snapshot.docs[0].data().product);
        const subscription = snapshot.docs[0].data();
        const productId = subscription.product.id;

        getDoc(doc(db, "products", productId)).then((document) => {
          if (document.exists) {
            setActiveProduct(document.data());
            console.log(document.data());
            setLoading(false);
          }
        });
      });
    };
    getRole();
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="upgrade flex flex-col min-h-screen  items-center sm:items-start gap-4 w-full sm:flex-row">
      {" "}
      {!loading ? (
        <div className="flex flex-col   max-w-7xl w-full pl-5 pr-5 sm:ml-80">
          <div className="h-8 w-full sm:h-16 "> </div>
          <h1 className="text-3xl font-extrabold">Upgrade</h1>

          <div className="flex justify-center mt-8 mb-8">
            <div className="text-lg font-semibold">
              Monthly{" "}
              <Switch
                checked={toggleYearly}
                onClick={() => {
                  setToggleYearly(!toggleYearly);
                }}
                style={{ backgroundColor: "black", margin: "0px 10px" }}
              />{" "}
              Yearly
            </div>
          </div>

          <div className="flex flex-wrap xl:flex-nowrap justify-center gap-8">
            {toggleYearly
              ? yearlyFeatures.map((feature) => {
                  // return <SinglePrice active={true} data={feature} />;
                  return (
                    <SinglePrice
                      key={feature.name}
                      data={feature}
                      userSubscription={userSubscription}
                      active={feature.name === activeProduct.name}
                    />
                  );
                })
              : monthlyFeatures.map((feature) => {
                  return (
                    <SinglePrice
                      key={feature.name}
                      userSubscription={userSubscription}
                      active={feature.name === activeProduct.name}
                      data={feature}
                    />
                  );
                })}
          </div>
        </div>
      ) : (
        // this part is just a skeleton
        <div className="flex flex-col   max-w-7xl w-full pl-5 pr-5 sm:ml-80">
          <div className="h-8 w-full sm:h-16 "> </div>
          <h1 className="text-3xl font-extrabold">Upgrade</h1>

          <div className="flex justify-center mt-8 mb-8">
            <div className="text-lg font-semibold">
              Monthly{" "}
              <Switch
                checked={toggleYearly}
                onClick={() => {
                  setToggleYearly(!toggleYearly);
                }}
                style={{ backgroundColor: "black", margin: "0px 10px" }}
              />{" "}
              Yearly
            </div>
          </div>

          <div className="flex flex-wrap xl:flex-nowrap w-full justify-center gap-8">
            {/* skeleton of table */}
            <div
              className={`flex flex-col items-center gap-6 justify-center  w-full shadow pt-8 pb-8 rounded-lg p-4 max-w-xs `}
            >
              <SkeletonNode active> </SkeletonNode>
              <Skeleton active paragraph={{ rows: 5 }}></Skeleton>
              <SkeletonInput active size={"small"} block />
              <SkeletonInput active size={"small"} />
            </div>
            <div
              className={`flex flex-col items-center gap-6 justify-center  w-full shadow pt-8 pb-8 rounded-lg p-4 max-w-xs `}
            >
              <SkeletonNode active> </SkeletonNode>
              <Skeleton active paragraph={{ rows: 5 }}></Skeleton>
              <SkeletonInput active size={"small"} block />
              <SkeletonInput active size={"small"} />
            </div>
            <div
              className={`flex flex-col items-center gap-6 justify-center  w-full shadow pt-8 pb-8 rounded-lg p-4 max-w-xs `}
            >
              <SkeletonNode active> </SkeletonNode>
              <Skeleton active paragraph={{ rows: 5 }}></Skeleton>
              <SkeletonInput active size={"small"} block />
              <SkeletonInput active size={"small"} />
            </div>
            <div
              className={`flex flex-col items-center gap-6 justify-center  w-full shadow pt-8 pb-8 rounded-lg p-4 max-w-xs `}
            >
              {/* <SkeletonImage active /> */}
              <SkeletonNode active> </SkeletonNode>
              <Skeleton active paragraph={{ rows: 5 }}></Skeleton>
              <SkeletonInput active size={"small"} block />
              <SkeletonInput active size={"small"} />
            </div>
          </div>
        </div>
      )}
      {/* <div className="flex flex-col   max-w-7xl w-100 pl-5 pr-5 sm:ml-80">
        <div className="h-8 w-full sm:h-16 "> </div>
        <h1 className="text-3xl font-extrabold">Upgrade</h1>

        <div className="flex justify-center mt-8 mb-8">
          <div className="text-lg font-semibold">
            Monthly{" "}
            <Switch
              checked={toggleYearly}
              onClick={() => {
                setToggleYearly(!toggleYearly);
              }}
              style={{ backgroundColor: "black", margin: "0px 10px" }}
            />{" "}
            Yearly
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-8">
          {toggleYearly
            ? yearlyFeatures.map((feature) => {
                // return <SinglePrice active={true} data={feature} />;
                return <SinglePrice data={feature} />;
              })
            : monthlyFeatures.map((feature) => {
                return <SinglePrice active={userSubscription} data={feature} />;
              })}
        </div>
      </div> */}
    </div>
  );
};

export default Upgrade;
