import React from "react";
import logo from "../../assets/logo/logo.svg";

// import { FacebookAuthProvider } from "firebase/auth"

import { FaceFrownIcon } from "@heroicons/react/24/outline";

import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";

// import { useNavigate } from "react-router-dom/dist";

const Login = ({ error }) => {
  // const navigate = useNavigate();

  return (
    <div className="w-full h-screen ">
      <div className="bg-primary flex flex-col items-center  gap-10 h-full w-full pt-10">
        <div className="flex flex-col items-center justify-around gap-7  ">
          <div className="flex-shrink-0 w-3/4 ">
            <img className="  w-full " src={logo} alt="Your Company" />
          </div>
          <div className="text-white text-3xl text-center pl-4 pr-4 font-bold">
            Oops! Something Went Wrong
          </div>{" "}
        </div>
        <div className=" flex flex-col items-center  ">
          <FaceFrownIcon className="w-full text-white" />
          <div className="text-white">We are sorry for the inconvenience</div>
        </div>

        <div className="text-red-400">{error}</div>
        <PrimaryButton
          onClick={() => {
            window.location = "/";
          }}
        >
          Navigate to Home
        </PrimaryButton>

        <a
          href={`mailto:videosubtitles@enzartec.com?subject = Got this error on VideoSubtitles&body = ${error}`}
          className="text-white border-b-lime-400 border-b-2 "
        >
          {" -->"} Report it to support team
        </a>
      </div>
    </div>
  );
};

export default Login;
// {/* <div className="flex-shrink-0 sm:hidden">
//   <img className="h-8  w-64 " src={logo} alt="Your Company" />
// </div>
// <div className="text-white text-3xl text-center pl-4 pr-4 sm:hidden">
//   Add Subtitles to your videos with ease
// </div>{" "}
// <div className=" h-80  bg-slate-100 rounded-3xl  flex flex-col items-center shadow p-5">
//   <h1 className="text-3xl font-extrabold">Get Started..</h1>
// </div> */}
