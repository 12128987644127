import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lib";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
import SubtitleField from "../../components/SubtitleField/SubtitleField";
// import Usermenu from "../../components/UserMenu/Usermenu";
import { useParams } from "react-router-dom";
import { UserContext } from "../../App";
import {
  // collection,
  doc,
  // getDoc,
  onSnapshot,
  // orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import "./SubtitleEditor.css";
// import { RadioGroup } from "@headlessui/react";
import { getTagforStatus } from "../../Util/getTagForStatus";
import convertSecondsToHMSM from "../../Util/convertSecondsToHMSM";
import SkeletonInput from "antd/es/skeleton/Input";
import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonImage from "antd/es/skeleton/Image";
import {
  //  Button, Modal,
  Skeleton,
} from "antd";
// import { SyncOutlined } from "@ant-design/icons";

import Confirm from "../../components/Confirm/Confirm.js";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton.js/SecondaryButton";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import GearLoader from "../../components/GearLoader/GearLoader";
// import { httpsCallableFromURL } from "firebase/functions";

// import { parseTimestamp } from "subtitle";

var timeoptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};
const SubtitleEditor = () => {
  const user = useContext(UserContext);
  // const [apiResponse, setApiResponse] = useState("");
  const { id } = useParams();
  const [job, setJob] = useState({ cues: [] });

  const [currentTime, setCurrentTime] = useState(0);
  const downloadType = useRef("srt");
  const handleTimeUpdate = ({ playedSeconds }) => {
    setCurrentTime(playedSeconds);
  };
  const getCurrentSubtitles = () => {
    return job.cues.filter((subtitle) => {
      return subtitle.start <= currentTime && subtitle.end >= currentTime;
    });
  };

  const [loading, setLoading] = useState(true);
  const [resetOpen, setResetOpen] = useState(false);
  // const [srtFile, setSrtFile] = useState("");
  // const [vttFile, setVttFile] = useState("");
  // const [vttUrl, setVttUrl] = useState("");
  // const [txtFile, setTxtFile] = useState("");

  useEffect(() => {
    const docref = query(doc(db, "users", user.uid, "jobs", id));
    const unsub = onSnapshot(docref, (doc) => {
      if (doc.empty) {
        setLoading(true);
        setJob(null);

        setLoading(false);
      } else {
        setJob({ ...doc.data(), id: id });
        console.log("listener called");
        console.log({ ...doc.data(), id: id });
        setLoading(false);
      }
    });

    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatTime(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor((timeInSeconds % 1) * 1000);

    return `${hours}:${padZero(minutes)}:${padZero(seconds)}.${padZero(
      milliseconds,
      3
    )}`;
  }

  function padZero(value, length = 2) {
    return String(value).padStart(length, "0");
  }
  function generateAssFile(cues) {
    let assContent = "; Script generated by videosubtitles.io\r\n";
    assContent += "ScriptType: v4.00+\r\n";
    assContent += "PlayResX: 384\r\n";
    assContent += "PlayResY: 288\r\n";
    assContent += "ScaledBorderAndShadow: yes\r\n";

    assContent += "\r\n[V4+ Styles]\r\n";
    assContent +=
      "Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding\r\n";
    assContent +=
      "Style: Default,Arial,16,&Hffffff,&Hffffff,&H0,&H0,0,0,0,0,100,100,0,0,1,1,0,2,10,10,10,0\r\n";

    assContent += "\r\n[Events]\r\n";
    assContent +=
      "Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text\r\n";

    cues.forEach((cue, index) => {
      const { start, end, text } = cue;
      const startTime = formatTime(start);
      const endTime = formatTime(end);
      const cueLine = `Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,${text}\r\n`;
      assContent += cueLine;
    });

    return assContent;
  }
  const downloadFile = () => {
    console.log(downloadType.current.value);
    let data = "";
    if (downloadType.current.value === "txt") {
      job.cues.forEach((cue, i) => {
        data = data + " " + cue.text + "\n";
      });

      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      // setVttUrl(url);
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", job.initialFileName.split(".")[0]);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    } else if (downloadType.current.value === "srt") {
      job.cues.forEach((cue, i) => {
        data += `${i + 1}\n${convertSecondsToHMSM(
          cue.start,
          "coma"
        )} --> ${convertSecondsToHMSM(cue.end, "coma")}\n${cue.text}\n\n`;
      });
      const blob = new Blob([data], { type: "text/srt" });
      const url = URL.createObjectURL(blob);
      // setVttUrl(url);
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute(
        "download",
        job.initialFileName.split(".")[0] + ".srt"
      );
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    }

    if (downloadType.current.value === "vtt") {
      data = "WEBVTT\n\n";
      job.cues.forEach((cue, i) => {
        data += `${convertSecondsToHMSM(cue.start)} --> ${convertSecondsToHMSM(
          cue.end
        )}\n${cue.text}\n\n`;
      });
      const blob = new Blob([data], { type: "text/vtt" });
      const url = URL.createObjectURL(blob);
      // setVttUrl(url);
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute(
        "download",
        job.initialFileName.split(".")[0] + ".vtt"
      );
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    }
    if (downloadType.current.value === "ass") {
      const assContent = generateAssFile(job.cues);
      const element = document.createElement("a");
      const file = new Blob([assContent], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${job.initialFileName.split(".")[0]}.ass`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    // const element = document.createElement("a");
    // element.setAttribute(
    //   "href",
    //   "data:" + type + ";charset=utf-8," + encodeURIComponent(data)
    // );
    // element.setAttribute("download", filename);
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  };

  const handleSubtitleTimeChange = (index, newStart, newEnd) => {
    setJob((prevJob) => {
      const updatedCues = [...prevJob.cues];
      updatedCues[index] = {
        ...updatedCues[index],
        start: newStart,
        end: newEnd,
      };
      return {
        ...prevJob,
        cues: updatedCues,
      };
    });
  };
  const handleSubtitleTextChange = (index, value) => {
    setJob((prevJob) => {
      const updatedCues = [...prevJob.cues];
      updatedCues[index] = {
        ...updatedCues[index],
        text: value,
      };
      return {
        ...prevJob,
        cues: updatedCues,
      };
    });
  };
  const deleteSubtitle = (indexToDelete) => {
    setJob((prevJob) => {
      setLoading(true);
      const newCues = job.cues.filter((cue, index) => index !== indexToDelete);
      return {
        ...prevJob,
        cues: newCues,
      };
    });
    setLoading(false);
  };
  const resetChanges = async () => {
    setLoading(true);
    const docref = query(doc(db, "users", user.uid, "jobs", id));
    setDoc(docref, { cues: job.originalCues }, { merge: true })
      .then(() => {
        setJob((prevJob) => {
          const newCues = job.originalCues;
          return {
            ...prevJob,
            cues: newCues,
          };
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const saveChanges = async () => {
    setLoading(true);
    const docref = query(doc(db, "users", user.uid, "jobs", id));
    setDoc(docref, job, { merge: true })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="subtitle-editor flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row pb-10">
      {" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <Confirm
        description={
          " Do you want to reset subtitles to its original state, all your changes will be lost"
        }
        open={resetOpen}
        setopen={setResetOpen}
        cancelFunction={() => {
          console.log("reset canceled");
        }}
        okFunction={resetChanges}
        okText={"reset"}
      />
      {!loading && job && (
        <div className="flex flex-col items-center sm:items-start  max-w-7xl w-full pl-5 pr-5 gap-5 sm:ml-72 lg:ml-80">
          <div className="h-8 w-full sm:h-16 "> </div>{" "}
          <h1 className="text-3xl font-extrabold">Subtitle Editor</h1>{" "}
          <div className="flex items-center justify-between  gap-3 p-7 bg-gray-100 rounded-xl w-full flex-wrap shadow">
            {" "}
            <div className="flex gap-5 items-center flex-wrap">
              <div className="flex flex-col">
                <div>{job.initialFileName}</div>
                <div className="text-xs text-gray-700">
                  {" "}
                  {job.addedDate
                    .toDate()
                    .toLocaleDateString("en-US", timeoptions)}
                </div>
              </div>
              <div>{getTagforStatus(job.status)}</div>
            </div>
            {/* <MainButton
              onClick={async () => {
                const burnVideo = httpsCallableFromURL(
                  functions,
                  // "http://127.0.0.1:5001/videosubtitle-bb29b/us-central1/burnVideo"
                  "https://us-central1-videosubtitle-bb29b.cloudfunctions.net/burnVideo"
                );
                await burnVideo({ userId: user.uid, jobId: job.id });
              }}
              disabled={job.status !== "completed"}
            >
              Burn
            </MainButton> */}
            <div className="flex gap-3 ">
              {" "}
              <select className="border-0 bg-gray-100" ref={downloadType}>
                <option>srt</option>
                <option>vtt</option>
                <option>txt</option>
                <option>ass</option>
              </select>
              <MainButton
                onClick={downloadFile}
                disabled={job.status !== "completed"}
              >
                Download
              </MainButton>
            </div>
          </div>
          {job.status === "completed" ? (
            <div className="w-full flex  flex-col lg:flex-row lg:gap-9  ">
              <div className="flex flex-col gap-4 w-full max-h-96 lg:w-1/2  ">
                {" "}
                <ReactPlayer
                  width={"100%"}
                  // height={
                  //   job.fileType && job.fileType.includes("video")
                  //     ? "100%"
                  //     : "100px"
                  // }
                  onProgress={handleTimeUpdate}
                  url={job.mediaURL}
                  // onProgress={handleTimeUpdate}

                  controls={true}
                />
                <div className="rounded-lg w-full bg-gray-800 text-white p-4 ">
                  {getCurrentSubtitles().map((subtitle, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          left: `${(subtitle.start / currentTime) * 100}%`,
                        }}
                      >
                        {subtitle.text}
                      </div>
                    );
                  })}
                </div>
                <div className="flex w-full justify-between">
                  {/* <button
                    className="secondary-btn"
                    onClick={() => {
                      setResetOpen(true);
                    }}
                  >
                    Reset
                  </button> */}
                  <SecondaryButton
                    danger={false}
                    onClick={() => {
                      setResetOpen(true);
                    }}
                  >
                    Reset
                  </SecondaryButton>

                  <PrimaryButton onClick={saveChanges}>
                    {" "}
                    Save changes
                  </PrimaryButton>
                </div>
              </div>
              <div
                className="flex flex-col gap-5 max-h-400px w-full lg:w-1/2  box-border lg:overflow-y-auto  "
                style={window.innerWidth > 1024 ? { maxHeight: "500px" } : {}}
              >
                {job &&
                  job.cues.map((cue, i) => {
                    return (
                      <SubtitleField
                        key={i}
                        active={
                          cue.start <= currentTime && cue.end >= currentTime
                        }
                        startLimit={i === 0 ? 0 : job.cues[i - 1].end}
                        endLimit={
                          i === job.cues.length - 1
                            ? job.duration
                            : job.cues[i + 1].start
                        }
                        start={cue.start}
                        end={cue.end}
                        text={cue.text}
                        totalcues={job.cues.length}
                        position={i + 1}
                        deleteSubtitle={deleteSubtitle}
                        handleSubtitleTimeChange={handleSubtitleTimeChange}
                        handleSubtitleTextChange={handleSubtitleTextChange}
                      />
                    );
                  })}
              </div>
            </div>
          ) : job.status !== "failed" ? (
            <div className="w-80 flex flex-col justify-center items-center self-center">
              {/* <img src={Gear} alt="" /> */}
              <GearLoader />
              <div>
                This might take a minute or two (larger files may take a little
                longer)
              </div>
            </div>
          ) : (
            <div className="w-80 flex flex-col justify-center items-center self-center">
              <div className="text-lg font-bold">Something went wrong</div>
              <div className="text-sm text-red-500">{job.error ?? ""}</div>
            </div>
          )}
        </div>
      )}
      {/* if job not foeund and is null */}
      {!job && (
        <div className="flex flex-col items-center sm:items-start  max-w-7xl w-full pl-5 pr-5 gap-5 sm:ml-72 lg:ml-80">
          <div className="h-8 w-full sm:h-16 "> </div>{" "}
          <h1 className="text-3xl font-extrabold">Subtitle Editor</h1>{" "}
          <div className="flex items-center justify-between  gap-3 p-7 bg-gray-100 rounded-xl w-full flex-wrap shadow">
            {" "}
            <div className="flex gap-5 items-center flex-wrap">
              <div className="flex flex-col">
                <SkeletonInput active />
                <div className="text-xs text-gray-700"> </div>
              </div>
              {/* <div>{getTagforStatus(job.status)}</div> */}
            </div>
            <div className="flex gap-3 ">
              {" "}
              <SkeletonButton active />
            </div>
          </div>
          <div className="w-full flex  flex-col lg:flex-row lg:gap-9  ">
            <div className="flex flex-col gap-4 w-full  lg:w-1/2  ">
              {" "}
              <SkeletonImage
                active
                block
                style={{ width: " 100%", minHeight: "225px" }}
              />
              <SkeletonInput active block />
              <div className="flex w-full justify-between">
                <SkeletonButton active />
                <SkeletonButton active />
              </div>
            </div>
            <div
              className="flex flex-col gap-5 max-h-400px w-full lg:w-1/2  box-border lg:overflow-y-auto "
              style={window.innerWidth > 1024 ? { maxHeight: "500px" } : {}}
            >
              {job && (
                <div className="flex flex-col gap-5">
                  <Skeleton active />
                  <Skeleton active />
                </div>
              )}

              {/* <SubtitleField />
           <SubtitleField active />
           <SubtitleField />
           <SubtitleField active />
           <SubtitleField />
           <SubtitleField />
           <SubtitleField active /> */}
            </div>
          </div>
        </div>
      )}
      {/* skeleton for loading */}
      {loading && (
        <div className="flex flex-col items-center sm:items-start  max-w-7xl w-full pl-5 pr-5 gap-5 sm:ml-72 lg:ml-80">
          <div className="h-8 w-full sm:h-16 "> </div>{" "}
          <h1 className="text-3xl font-extrabold">Subtitle Editor</h1>{" "}
          <div className="flex items-center justify-between  gap-3 p-7 bg-gray-100 rounded-xl w-full flex-wrap shadow">
            {" "}
            <div className="flex gap-5 items-center flex-wrap">
              <div className="flex flex-col">
                <SkeletonInput active />
                <div className="text-xs text-gray-700"> </div>
              </div>
              {/* <div>{getTagforStatus(job.status)}</div> */}
            </div>
            <div className="flex gap-3 ">
              {" "}
              <SkeletonButton active />
            </div>
          </div>
          <div className="w-full flex  flex-col lg:flex-row lg:gap-9  ">
            <div className="flex flex-col gap-4 w-full  lg:w-1/2  ">
              {" "}
              <SkeletonImage active />
              <SkeletonInput active block />
              <div className="flex w-full justify-between">
                <SkeletonButton active />
                <SkeletonButton active />
              </div>
            </div>
            <div
              className="flex flex-col gap-5 max-h-400px w-full lg:w-1/2  box-border lg:overflow-y-auto "
              style={window.innerWidth > 1024 ? { maxHeight: "500px" } : {}}
            >
              {job && (
                <div className="flex flex-col gap-5">
                  <Skeleton active />
                  <Skeleton active />
                </div>
              )}

              {/* <SubtitleField />
            <SubtitleField active />
            <SubtitleField />
            <SubtitleField active />
            <SubtitleField />
            <SubtitleField />
            <SubtitleField active /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubtitleEditor;
