import { StarIcon } from "@heroicons/react/24/outline";
import { Badge } from "antd";
import React, { useContext, useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import MainButton from "../Buttons/MainButton/MainButton";
import createCheckoutSession from "../../stripe/createCheckoutSession";
import { UserContext } from "../../App";


const SinglePrice = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);

  const {
    offPercent,
    plan,
    previousPrice,
    price,
    chargeMessage,
    features,
    icon,
  } = props.data;
  const { active } = props;
  const { userSubscription } = props;
  return (
    <Badge.Ribbon
      text={`${offPercent ? offPercent + "% off" : ""}`}
      color={plan === "pro" ? "yellow" : "green"}
      style={!offPercent && { display: "none" }}
    >
      <div
        className={`flex flex-col items-center gap-6  shadow pt-8 pb-8 rounded-lg p-4 max-w-xs ${
          active ? " border-black border" : ""
        } ${plan === "pro" ? " shadow-lime-500 shadow-md" : ""}`}
      >
        <div className=" font-bold text-3xl uppercase">{plan}</div>
        <div className={`${plan === "free" ? "w-20 pt-2 " : "w-28"}`}>
          {" "}
          <img className="w-full" src={icon} alt="plan-icon" />{" "}
        </div>
        <div className="flex gap-1 flex-col items-center  align-bottom justify-center  ">
          <div
            className={`${
              !previousPrice ? "pr-0" : "pr-11"
            } font-bold text-xl self-start text-center w-full `}
          >
            <s className="text-gray-500">
              {previousPrice && previousPrice + "$"}
            </s>{" "}
            {price}${" "}
          </div>
          <div className="font-normal text-sm italic text-gray-500 ">
            {chargeMessage ?? ""}
          </div>
        </div>

        <div className="flex flex-col g-3 items-start">
          {features.map((feature) => {
            return (
              <div className="flex items-center gap-1">
                <StarIcon className="w-4 fill-lime-400" /> {feature}
              </div>
            );
          })}
        </div>
        <div className="text-center">
          1 credit = 1 minute of video/audio processing
        </div>
        {plan === "pro" ? (
          <MainButton
            onClick={() => {
              if (userSubscription) {
                setLoading(true);
                window.location.href =
                  "billing.videosubtitles.io/p/login/00g7uNgEV9VJ93yfYY";
              } else {
                setLoading(true);
                let str = chargeMessage.includes("month")
                  ? "Monthly"
                  : "Yearly";
                console.log(user.id, plan + str);
                createCheckoutSession(user.id, plan + str);
              }
            }}
            loading={loading}
            disabled={active}
          >
            {active ? "active" : "Upgrade"}
          </MainButton>
        ) : (
          <PrimaryButton
            disabled={active}
            onClick={() => {
              if (userSubscription) {
                setLoading(true);
                window.location.href =
                  "billing.videosubtitles.io/p/login/00g7uNgEV9VJ93yfYY";
              } else {
                setLoading(true);
                let str = chargeMessage.includes("month")
                  ? "Monthly"
                  : "Yearly";
                console.log(user.id, plan + str);
                createCheckoutSession(user.id, plan + str);
              }
            }}
            loading={loading}
          >
            {" "}
            {active ? "active" : plan === "free" ? "Downgrade" : "Upgrade"}
          </PrimaryButton>
        )}
      </div>
    </Badge.Ribbon>
  );
};

export default SinglePrice;
