import React from "react";
import "./App.css";
import { useState } from "react";
import { createContext } from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom/dist";
import { auth, db } from "./firebase";
import Contact from "./pages/Contact/Contact";
// import Header from "./components/Header/Header";
// import Sidebar from "./components/Sidebar/Sidebar";
// import Usermenu from "./components/UserMenu/Usermenu";
import History from "./pages/History/History";
// import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Settings from "./pages/Settings/Settings";
// import Reactplayer from "./pages/SubtitleEditor.js/ReactPlayer";
import SubtitleEditor from "./pages/SubtitleEditor.js/SubtitleEditor";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import BasicLoader from "./components/BasicLoader/BasicLoader";
import { doc, onSnapshot } from "firebase/firestore";
import Upgrade from "./pages/Upgrade/Upgrade";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Usermenu from "./components/UserMenu/Usermenu";
import Footer from "./components/Footer/Footer";
import { message } from "antd";
import Account from "./pages/Account/Account";
import { Crisp } from "crisp-sdk-web";
import Home2 from "./pages/Home/home2";
import SubtitleEditor2 from "./pages/SubtitleEditor.js/SubtitleEditor2";

// import Media from "./pages/Media/Media";
// import Footer from "./components/Footer/Footer";
export const UserContext = createContext();
export const AppMessage = createContext();
function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  //get current location from react router dom
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    // throw new Error("something fishy");
    Crisp.configure("ac0d4e58-0083-4b94-bf89-7a9e26ba73fb");
    let unsub = () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps

    auth.onAuthStateChanged(async (user) => {
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      if (user) {
        unsub = onSnapshot(doc(db, "users", user.uid), (doc) => {
          setLoading(true);
          setCurrentUser({
            id: user.uid,
            uid: user.uid,
            photoUrl: user.photoURL,
            ...user.metadata,

            ...doc.data(),
          });

          Crisp.user.setEmail(user.email);
          Crisp.user.setNickname(user.name ?? user.displayName);

          Crisp.session.setData({
            plan: decodedToken?.claims?.stripeRole ?? "free",
          });

          setLoading(false);
        });
      } else {
        setCurrentUser(null);
        setLoading(false);
        // navigate("/login");
      }
    });

    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={currentUser}>
      <AppMessage.Provider value={messageApi}>
        {contextHolder}
        <div className=" relative">
          {!loading && (
            <>
              {currentUser && (
                <>
                  <Header />
                  <Sidebar />
                  <Usermenu />
                </>
              )}

              <Routes>
                <Route exact path="/login" element={<Login />} />

                {currentUser ? (
                  <>
                    <Route path="/" element={<Home2 />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/subtitles/:id" element={<SubtitleEditor />} />
                    {/* <Route
                      path="/subtitles/:id"
                      element={<SubtitleEditor2 />}
                    /> */}
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/upgrade" element={<Upgrade />} />
                    {/* <Route path="/media" element={<Media />} /> */}
                    <Route path="*" element={<PageNotFound />} />
                  </>
                ) : (
                  <Route path="*" element={<Login />} />
                )}
              </Routes>

              {currentUser && (
                <>
                  <Footer />
                </>
              )}
            </>
          )}
          {loading && <BasicLoader />}
        </div>
      </AppMessage.Provider>
    </UserContext.Provider>
  );
}

export default App;
