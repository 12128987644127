import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Cog6ToothIcon,
  EnvelopeIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { HistoryOutlined } from "@ant-design/icons";
import logo from "../../assets/logo/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { stringAvatar } from "../../Util/stringAvatar";
import { Avatar } from "antd";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
// import ToolTip from "../Tip/Tip";
import getLimitForPlan from "../../Util/getLimitForSubscription";
import React from "react";
const navigation = [
  {
    name: "Home",
    href: "/",
    current: true,
    icon: <HomeIcon className="w-4" />,
  },
  {
    name: "History",
    href: "/history",
    current: false,
    icon: <HistoryOutlined className="w-4" />,
  },
  {
    name: "Settings",
    href: "/settings",
    current: false,
    icon: <Cog6ToothIcon className="w-4" />,
  },
  {
    name: "Contact",
    href: "/contact",
    current: false,
    icon: <EnvelopeIcon className="w-4" />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [falseImage, setFalseImage] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const userNavigation = [
    // { name: "Account", href: "#", click: () => {} },
    {
      name: "Settings",
      href: "#",
      click: () => {
        navigate("/settings");
      },
    },
    {
      name: "Sign out",
      href: "#",
      click: () => {
        signOut(auth)
          .then(() => {
            // Sign-out successful.
          })
          .catch((error) => {
            // An error happened.
            console.log(error);
          });
      },
    },
  ];
  const [userSubscription, setUserSubscription] = useState("free");
  const [userlimit, setUserLimit] = useState(null);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getRole = async () => {
      setLoading(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      decodedToken?.claims?.stripeRole
        ? setUserSubscription(decodedToken?.claims?.stripeRole)
        : setUserSubscription("free");
      console.log(decodedToken?.claims?.stripeRole);

      setUserLimit(getLimitForPlan(decodedToken?.claims?.stripeRole ?? "free"));

      setLoading(false);
    };
    getRole();
  }, [user]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full w-full sm:hidden">
        <Disclosure as="nav" className="bg-primary">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-8 w-32" src={logo} alt="Your Company" />
                    </div>
                  </div>

                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-black p-2 text-white hover:bg-gray-700 hover:text-white  ">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                          fill="white"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      href={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? "bg-lime-500 text-black"
                          : "text-gray-300 hover:bg-gray-900 hover:text-white",
                        " rounded-md px-3 py-2 text-base font-medium flex items-center gap-2"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.icon} {item.name}
                    </Link>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      {falseImage ? (
                        <Avatar {...stringAvatar(user.name)}></Avatar>
                      ) : (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.photoURL}
                          onError={() => {
                            setFalseImage(true);
                          }}
                          alt={`${user.name} profile pic`}
                        />
                      )}
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user.displayName}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user.email}
                      </div>
                    </div>
                    {/* <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                    <div className="flex flex-col items-end">
                      <div className="text-sm flex gap-2 items-center text-white">
                        <span className="font-bold text-white">
                          {userSubscription === "free"
                            ? user.trialCredits
                            : userlimit - user.creditsConsumed}
                        </span>{" "}
                        credits remaining
                      </div>
                      <div
                        className="text-sm uppercase text-lime-500 font-bold cursor-pointer"
                        onClick={() => {
                          navigate("/upgrade");
                          open = false;
                        }}
                      >
                        upgrade
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        onClick={item.click}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
