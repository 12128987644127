/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import {
  connectFirestoreEmulator,
  // connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
  getToken,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAHZBsYt7Vd4cA7H2GHfEoeHppzZw6eY90",
  authDomain: "app.videosubtitles.io",
  projectId: "videosubtitle-bb29b",
  storageBucket: "videosubtitle-bb29b.appspot.com",
  messagingSenderId: "322037553695",
  appId: "1:322037553695:web:01e117d89d014a5989532a",
  measurementId: "G-5P2NZ5EK5B",
};

// // Initialize Firebase
// // local
// export const app = initializeApp(firebaseConfig);
// export const db = getFirestore();

// connectFirestoreEmulator(db, "localhost", 8081);
// export const auth = getAuth();
// connectAuthEmulator(auth, "http://localhost:9099");
// export const storage = getStorage();

// // Point to the Storage emulator running on localhost.
// connectStorageEmulator(storage, "localhost", 9199);

// export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 4000);

//production

export const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Lc2CdclAAAAAHkVAeI25XANwA3e2w2G7a_t_76W"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
getToken(appCheck)
  .then(() => {
    console.log("success");
  })
  .catch((error) => {
    console.log(error.message);
  });
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
