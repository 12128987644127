import {
  Cog6ToothIcon,
  EnvelopeIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { HistoryOutlined } from "@ant-design/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo/logo.svg";
const navigation = [
  {
    name: "Home",
    href: "/",
    current: true,
    icon: <HomeIcon className="w-4" />,
  },
  {
    name: "History",
    href: "/history",
    current: false,
    icon: <HistoryOutlined className="w-4" />,
  },
  {
    name: "Settings",
    href: "/settings",
    current: false,
    icon: <Cog6ToothIcon className="w-4" />,
  },
  {
    name: "Contact",
    href: "/contact",
    current: false,
    icon: <EnvelopeIcon className="w-4" />,
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="bg-primary min-h-screen   hidden pt-4 sm:flex sm:flex-col gap-8  sm:items-start sm:w-1/4 sm:max-w-xs min-w-max fixed   ">
      <div className="flex-shrink-0 ">
        <img className="h-8  w-64 " src={logo} alt="Your Company" />
      </div>
      <div className="flex flex-col w-10/12 ml-3 ">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={classNames(
              location.pathname === item.href
                ? "bg-lime-500 text-black"
                : "text-gray-300 hover:bg-gray-900 hover:text-white",
              "rounded-md px-3 py-2 text-sm font-medium flex items-center gap-2 "
            )}
            aria-current={item.current ? "page" : undefined}
          >
            {item.icon} {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
