import { EnvelopeIcon } from "@heroicons/react/24/outline";
import React from "react";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Usermenu from "../../components/UserMenu/Usermenu";

const Contact = () => {
  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row">
      {" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <div className="flex flex-col items-center gap-4 sm:items-start   max-w-7xl w-100 pl-5 pr-5 sm:ml-80">
        <div className="h-8 w-full sm:h-16 "> </div>
        <h1 className="text-3xl font-extrabold">Contact Us</h1>

        <div className=" w-full flex  gap-4 mt-5">
          <div className="flex gap-2">
            <EnvelopeIcon className="w-4" />{" "}
            <span className="font-bold">Email us : </span>
          </div>
          <a
            href="mailto:videosubtitles@enzartec.com"
            className="italic underline"
          >
            videosubtitles@enzartec.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
