// import { BriefcaseIcon, ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { Avatar, Skeleton } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import { AppMessage, UserContext } from "../../App";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Usermenu from "../../components/UserMenu/Usermenu";
// import "./settings.css";
// import createCheckoutSession from "../../stripe/createCheckoutSession";
import {  functions } from "../../firebase";
// import getLimitForPlan from "../../Util/getLimitForSubscription";
import { useNavigate } from "react-router-dom";
// import MainButton from "../../components/Buttons/MainButton/MainButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { stringAvatar } from "../../Util/stringAvatar";
import SecondaryButton from "../../components/Buttons/SecondaryButton.js/SecondaryButton";
import Confirm from "../../components/Confirm/Confirm";
// import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
// import { signOut } from "firebase/auth";
// import { signOut } from "firebase/auth";

const Account = () => {
  const user = useContext(UserContext);
  const messageApi = useContext(AppMessage);
  const [openDelete, setOpenDelete] = useState(false);
  // const [userSubscription, setUserSubscription] = useState("free");
  // const [userlimit, setUserLimit] = useState(null);

  const [loading, setLoading] = useState(false);
  const [falseImage, setFalseImage] = useState(false);
  const [delButtonLoading, setDelButtonLoading] = useState(false);
  const [loButtonLoading, setLoButtonLoading] = useState(false);
  const navigate = useNavigate("");

  // useEffect(() => {
  //   const getRole = async () => {
  //     setLoading(true);
  //     const decodedToken = await auth.currentUser?.getIdTokenResult(true);

  //     decodedToken?.claims?.stripeRole
  //       ? setUserSubscription(decodedToken?.claims?.stripeRole)
  //       : setUserSubscription("free");
  //     console.log(decodedToken?.claims?.stripeRole);

  //     setUserLimit(getLimitForPlan(decodedToken?.claims?.stripeRole ?? "free"));
  //     console.log();

  //     setLoading(false);
  //   };
  //   getRole();
  // }, [user]);

  // const functionRef = httpsCallable(
  //   functions,
  //   "ext-firestore-stripe-payments-createPortalLink"
  // );
  const deleteUserfunc = async () => {
    // const userdoc = doc(db, "users", user.uid);
    // await setDoc(userdoc, { isDeleted: true }, { merge: true });

    const deleteUser = httpsCallable(functions, "deleteUser");

    deleteUser({ ...user })
      .then((result) => {
        // Read result of the Cloud Function.
        const sanitizedMessage = result.data.text;
        console.log(sanitizedMessage);
        setDelButtonLoading(false);
        messageApi.open({
          type: "success",
          content: "User Deleted Successfully",
          duration: 5,
        });
        navigate("/");
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Something went wrong, " + error.message,
          duration: 5,
        });
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(` Error: ${code} - ${message}\n${details}`);
      });
  };
  const logoutFromAllSessions = async () => {
    // const userdoc = doc(db, "users", user.uid);
    // await setDoc(userdoc, { isDeleted: true }, { merge: true });

    const logoutUserFromAllSessions = httpsCallable(
      functions,
      "logoutUserFromAllSessions"
    );

    logoutUserFromAllSessions({ ...user })
      .then((result) => {
        // Read result of the Cloud Function.
        const sanitizedMessage = result.data.text;
        console.log(sanitizedMessage);
        setDelButtonLoading(false);
        messageApi.open({
          type: "success",
          content: "Logged out from all the sessions",
          duration: 5,
        });
        setDelButtonLoading(false);
        navigate("/");
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        messageApi.open({
          type: "error",
          content: "Something went wrong, " + error.message,
          duration: 5,
        });
        console.log(` Error: ${code} - ${message}\n${details}`);
      });
  };

  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row pb-6">
      {" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <div className="flex flex-col items-center gap-4 sm:items-start   max-w-7xl w-100 pl-5 pr-5 w-full sm:ml-72 md:ml-80">
        <Confirm
          description={
            " Do you want to Delete your account? \n This includes all your media and data.\n This can't be undone and you wont be able to login or signup with this email again"
          }
          open={openDelete}
          setopen={setOpenDelete}
          cancelFunction={() => {
            console.log(" canceled");
            setDelButtonLoading(false);
          }}
          okFunction={() => {
            deleteUserfunc();
          }}
          okText={"Delete Account"}
        />
        <div className="h-8 w-full sm:h-16 "> </div>
        <h1 className="text-3xl font-extrabold">Account</h1>

        {!loading && (
          <div className="  flex flex-col gap-6 ">
            {/* Account */}
            <div className="flex flex-col items-center sm:items-start gap-3 p-7 bg-gray-100 rounded-xl   shadow ">
              <div className="flex items-center gap-5">
                {falseImage ? (
                  <Avatar {...stringAvatar(user.name)}></Avatar>
                ) : (
                  <img
                    className="h-12 w-12 rounded-full"
                    src={user.photoURL}
                    onError={() => {
                      setFalseImage(true);
                    }}
                    alt={`${user.name} profile pic`}
                  />
                )}

                <div>
                  <div>{user.name}</div>
                </div>
              </div>
              <div className="flex items-center gap-5 mt-5 ">
                <div>
                  Email:{" "}
                  <span className="underline text-gray-800 italic">
                    {user.email}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-5  ">
                <div>
                  Phone:{" "}
                  <span className="underline text-gray-800 italic">
                    {user.phone ?? "null"}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-5  ">
                <PrimaryButton
                  loading={loButtonLoading}
                  onClick={() => {
                    setLoButtonLoading(true);
                    logoutFromAllSessions();
                  }}
                >
                  Log out of sessions
                </PrimaryButton>
              </div>
              <div className="flex items-center gap-5  ">
                <SecondaryButton
                  loading={delButtonLoading}
                  danger={true}
                  onClick={() => {
                    setOpenDelete(true);
                    setDelButtonLoading(true);
                  }}
                >
                  Delete My Account
                </SecondaryButton>
              </div>
            </div>
          </div>
        )}

        {/* skeleton */}
        {loading && (
          <div className=" w-full flex flex-col gap-6 ">
            {/* Account */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full cursor-pointer shadow ">
              <Skeleton
                avatar
                active
                paragraph={{
                  rows: 1,
                }}
              />
            </div>
            {/* Usage */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full shadow ">
              <div className="w-full flex flex-col gap-3">
                <Skeleton
                  avatar={{ shape: "square" }}
                  active
                  paragraph={{
                    rows: 0,
                  }}
                />
              </div>
            </div>
            {/* Plan */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full  shadow ">
              <Skeleton
                avatar={{ shape: "square" }}
                active
                paragraph={{
                  rows: 0,
                }}
              />
              <Skeleton.Input />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
