import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const getTagforStatus = (status) => {
  switch (status) {
    case "processing":
      return (
        <Tag
          icon={<SyncOutlined spin />}
          color="processing"
          className="flex items-center"
        >
          PROCESSING
        </Tag>
      );
    case "pending":
      return (
        <Tag color="yellow" className="flex items-center">
          PENDING
        </Tag>
      );
    case "converting":
      return (
        <Tag color="magenta" className="flex items-center">
          CONVERTING
        </Tag>
      );
    case "initiated":
      return <Tag className="flex items-center">INITIATED</Tag>;
    case "failed":
      return (
        <Tag
          icon={<CloseCircleOutlined />}
          color="error"
          className="flex items-center"
        >
          FAILED
        </Tag>
      );
    case "completed":
      return (
        <Tag
          icon={<CheckCircleOutlined />}
          color="success"
          className="flex items-center"
        >
          READY
        </Tag>
      );

    default:
      return <Tag className="flex items-center">Status undefined</Tag>;
  }
};
