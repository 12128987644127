import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import logo from "../../assets/logo/logo.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useContext } from "react";
import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";
import ToolTip from "../Tip/Tip";
import getLimitForPlan from "../../Util/getLimitForSubscription";
import { Avatar } from "antd";
// import SkeletonInput from "antd/es/skeleton/Input";
import { stringAvatar } from "../../Util/stringAvatar";
import SkeletonAvatar from "antd/es/skeleton/Avatar";

// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Usermenu() {
  const [falseImage, setFalseImage] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const userNavigation = [
    // { name: "Account", href: "#", click: () => {} },
    {
      name: "Settings",
      href: "#",
      click: () => {
        navigate("/settings");
      },
    },
    {
      name: "Sign out",
      href: "#",
      click: () => {
        signOut(auth)
          .then(() => {
            // Sign-out successful.
          })
          .catch((error) => {
            // An error happened.
            console.log(error);
          });
      },
    },
  ];
  const [userSubscription, setUserSubscription] = useState("free");
  const [userlimit, setUserLimit] = useState(null);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getRole = async () => {
      setLoading(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      decodedToken?.claims?.stripeRole
        ? setUserSubscription(decodedToken?.claims?.stripeRole)
        : setUserSubscription("free");
      console.log(decodedToken?.claims?.stripeRole);

      setUserLimit(getLimitForPlan(decodedToken?.claims?.stripeRole ?? "free"));

      setLoading(false);
    };
    getRole();
  }, [user]);
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}

      {!loading ? (
        <>
          {" "}
          {user && (
            <Disclosure
              as="nav"
              className="hidden sm:block fixed top-0 right-0 z-10"
            >
              {({ open }) => (
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                  <div className="flex h-16 items-center justify-between">
                    <div className="">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div className="flex gap-3 items-center">
                            <div className="flex flex-col items-center">
                              <div className="text-sm flex gap-2 items-center">
                                <ToolTip
                                  title={`1 credit equals 1 minute of video, rounded to the nearest minute. A 1:01 video counts as 2 credits, while a 1:00 video counts as 1 credit.`}
                                />
                                <span className="font-bold">
                                  {userSubscription === "free"
                                    ? user.trialCredits
                                    : userlimit - user.creditsConsumed}
                                </span>{" "}
                                credits remaining
                              </div>
                              <div
                                className="text-sm uppercase text-green-900 font-bold cursor-pointer"
                                onClick={() => {
                                  navigate("/upgrade");
                                }}
                              >
                                upgrade
                              </div>
                            </div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              {falseImage ? (
                                <Avatar {...stringAvatar(user.name)}></Avatar>
                              ) : (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={user.photoURL}
                                  onError={() => {
                                    console.log("false image");
                                    setFalseImage(true);
                                  }}
                                  alt={`${user.name} profile pic`}
                                />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      onClick={item.click}
                                      href={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Disclosure>
          )}
        </>
      ) : (
        <>
          <SkeletonAvatar />
        </>
      )}
    </>
  );
}
