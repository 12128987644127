import React from "react";
import "./GearLoader.css";

const GearLoader = (props) => {
  return (
    <div className={"gearloader " + props.className}>
      <span className="loader"></span>
    </div>
  );
};

export default GearLoader;
