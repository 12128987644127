import React from "react";

const Footer = () => {
  return (
    <footer className=" w-full absolute bottom-0 ">
      <div className=" text-center font-mono ">
        Powered by{" "}
        <span className="font-bold text-purple-900">Enzartec LLC</span>
        <span className="pl-4"> Version 0.1</span>
      </div>
    </footer>
  );
};

export default Footer;
