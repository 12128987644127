export default function getStorageLimit(plan) {
  switch (plan) {
    case "free":
      return 1048576000;

    case "starter":
      return 2097152000;

    case "pro":
      return 5242880000;

    case "max":
      return 10485760000;

    default:
      break;
  }
}
