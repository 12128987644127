// import { Tag } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTagforStatus } from "../../Util/getTagForStatus";
import convertSecondsToHMS from "../../Util/convertSecondsToHMS";
import { TrashIcon } from "@heroicons/react/24/outline";
import Confirm from "../Confirm/Confirm";
const SingleHistory = ({ job, deleteJob }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const { initialFileName, status, duration, addedDate, id } = job;
  const navigate = useNavigate();
  return (
    <div className="p-5 shadow rounded-2xl flex flex-col gap-2 w-full">
      <Confirm
        description={" Do you want you really want to delete this job?"}
        open={openDelete}
        setopen={setOpenDelete}
        cancelFunction={() => {
          console.log(" canceled");
        }}
        okFunction={() => {
          deleteJob(job);
        }}
        okText={"Delete"}
      />{" "}
      <div className="flex justify-between">
        <div>
          <div className="text-ellipsis"> {initialFileName} </div>
          <div className="flex items-center gap-3">
            Status: {getTagforStatus(status)}{" "}
          </div>
          <div>
            Duration:{" "}
            <span className="text-slate-600 text">
              {convertSecondsToHMS(duration)}
            </span>
          </div>
          <div>
            Added on:{" "}
            <span className="text-slate-600 text">
              {addedDate.toDate().toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })}
            </span>{" "}
          </div>
        </div>
        <div>
          {" "}
          <TrashIcon
            className="w-5 text-red-600 cursor-pointer"
            onClick={() => {
              setOpenDelete(true);
            }}
          />
        </div>
      </div>
      <button
        className="primary-btn1"
        disabled={status !== "completed" ? true : false}
        onClick={() => {
          navigate(`/subtitles/${id}`);
        }}
      >
        Edit or Download Subs
      </button>
    </div>
  );
};

export default SingleHistory;
