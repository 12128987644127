import { Empty, Skeleton, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
import SingleHistory from "../../components/SingleHistory/SingleHistory";
// import Usermenu from "../../components/UserMenu/Usermenu";
import { getTagforStatus } from "../../Util/getTagForStatus";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { UserContext } from "../../App";
import convertSecondsToHMS from "../../Util/convertSecondsToHMS";

import SkeletonButton from "antd/es/skeleton/Button";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import { TrashIcon } from "@heroicons/react/24/outline";

import Confirm from "../../components/Confirm/Confirm";
import SkeletonInput from "antd/es/skeleton/Input";

// const data = [
//   {
//     key: "1",
//     title: "JohnBrown.mp4",
//     duration: "00:02:32",
//     id: "dSDSSA432",
//     status: "processing",
//   },
//   {
//     key: "2",
//     title: "John-Snow.mp4",
//     duration: "00:06:32",
//     id: "dSDSSA432",
//     status: "completed",
//   },
//   {
//     key: "3",
//     title: "GOT.mp3",
//     duration: "00:02:32",
//     id: "dSDSSA432",
//     status: "completed",
//   },
//   {
//     key: "4",
//     title: "John Brown",
//     duration: "00:01:32",
//     id: "dSDSSA432",
//     status: "failed",
//   },
// ];
const History = () => {
  const user = useContext(UserContext);

  const [jobs, setJobs] = useState([]);

  const [screenWidth, setscreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const deleteFuncRef = useRef(() => {});
  useEffect(() => {
    window.addEventListener("resize", () => {
      setscreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const getJobs = async () => {
      setLoading(true);
      let temp = [];
      const colref = query(
        collection(db, "users", user.uid, "jobs"),
        orderBy("addedDate", "desc"),
        where("isDeleted", "==", false)
      );
      getDocs(colref).then((snapshots) => {
        snapshots.docs.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });

        setJobs([...temp]);
        setLoading(false);
      });
    };

    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const deleteJob = (job) => {
    console.log("delete function");
    const docref = query(doc(db, "users", user.id, "jobs", job.id));
    setDoc(docref, { isDeleted: true }, { merge: true })
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        console.log("deleted");
        navigate("/");
        // const deleteFiles = httpsCallable(functions, "deleteFiles");
        // deleteFiles(job)
        //   .then((result) => console.log(result.data.message))
        //   .catch((error) => console.error(error));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "TITLE",
      dataIndex: "initialFileName",
      key: "initialFileName",
      render: (text) => <div>{text}</div>,
      //  <a>{text}</a>,
    },

    {
      title: "DURATION",
      dataIndex: "duration",
      key: "duration",
      render: (_, { duration }) => (
        <div className="w-fit">{convertSecondsToHMS(duration)}</div>
      ),
    },

    {
      title: "ADDED",
      dataIndex: "addedDate",

      key: "addedDate",
      render: (_, { addedDate }) => {
        var options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        // console.log(date.toLocaleDateString('en-US', options));
        return (
          <div className="">
            {addedDate.toDate().toLocaleDateString("en-US", options)}
          </div>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <div className="w-fit">{getTagforStatus(status)}</div>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, job) => (
        <div className="flex gap-3">
          <PrimaryButton
            onClick={() => {
              navigate(`/subtitles/${job.id}`);
            }}
          >
            {" "}
            Edit or Download Subs
          </PrimaryButton>
          <TrashIcon
            className="w-5 text-red-600 cursor-pointer"
            onClick={() => {
              setOpenDelete(true);
              deleteFuncRef.current = () => {
                deleteJob(job);
              };
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row ">
      <Confirm
        description={" Do you want you really want to delete this job?"}
        open={openDelete}
        setopen={setOpenDelete}
        cancelFunction={() => {
          console.log("reset canceled");
        }}
        okFunction={deleteFuncRef.current}
        okText={"Delete"}
      />{" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <div className="flex flex-col items-center sm:items-start  max-w-7xl w-full pl-5 pr-5 gap-5 sm:ml-72 lg:ml-80 pb-10">
        <div className="h-8 w-full sm:h-16 "> </div>

        <div className="flex justify-between w-full ">
          {" "}
          <h1 className="text-3xl font-extrabold">History</h1>{" "}
          <MainButton
            onClick={() => {
              navigate("/");
            }}
          >
            Generate More
          </MainButton>
        </div>
        {screenWidth < 1070 ? (
          <MobileUI data={jobs} loading={loading} deleteJob={deleteJob} />
        ) : (
          <div className="flex flex-col items-center gap-5 w-full  ">
            <Table
              columns={columns}
              dataSource={jobs}
              className=" shadow rounded-lg w-full "
              rowKey="id"
              scroll={{ x: true }}
              pagination={false}
              locale={{
                emptyText:
                  jobs.length === 0 && !loading ? (
                    <Empty />
                  ) : (
                    <div className="w-full flex flex-col gap-3">
                      {" "}
                      <div className="flex justify-between gap-7 items-center">
                        {" "}
                        <Skeleton.Input
                          active={true}
                          block={true}
                          size={"small"}
                        />
                        <SkeletonInput
                          active={true}
                          size={"small"}
                          style={{ minWidth: "200px" }}
                        />
                      </div>
                      <div className="flex justify-between gap-7 items-center">
                        {" "}
                        <Skeleton.Input
                          active={true}
                          block={true}
                          size={"small"}
                        />
                        <SkeletonInput
                          active={true}
                          size={"small"}
                          style={{ minWidth: "200px" }}
                        />
                      </div>
                      <div className="flex justify-between gap-7 items-center">
                        {" "}
                        <Skeleton.Input
                          active={true}
                          block={true}
                          size={"small"}
                        />
                        <SkeletonInput
                          active={true}
                          size={"small"}
                          style={{ minWidth: "200px" }}
                        />
                      </div>
                    </div>
                  ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default History;

const MobileUI = ({ data, loading, deleteJob }) => {
  return (
    <div className="flex flex-col items-center gap-5 w-full">
      {loading ? (
        <>
          <div className="p-5 shadow rounded-2xl flex flex-col gap-2 w-full">
            <Skeleton active />
            <SkeletonButton style={{ width: "100%" }} active size="large" />
          </div>
          <div className="p-5 shadow rounded-2xl flex flex-col gap-2 w-full">
            <Skeleton active />
            <SkeletonButton style={{ width: "100%" }} active size="large" />
          </div>
        </>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              {data.map((item) => {
                return (
                  <SingleHistory
                    key={item.id}
                    job={item}
                    deleteJob={deleteJob}
                  />
                );
              })}
            </>
          ) : (
            <Empty />
          )}
        </>
      )}
      {/* <button>Load more</button> */}
    </div>
  );
};
