import { Button } from "antd";
import React from "react";
import "./SecondaryButton.css";

const SecondaryButton = (props) => {
  const { onClick, loading, danger } = props;
  return (
    <Button
      className="Sbutton"
      style={danger ? {} : { border: "1px solid black" }}
      loading={loading}
      onClick={onClick}
      danger={danger}
    >
      {props.children}
    </Button>
  );
};

export default SecondaryButton;
