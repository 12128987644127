export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  try {
    let children = "";
    name
      .split(" ")
      .slice(0, 2)
      .forEach((word) => {
        children += word[0];
      });

    return {
      style: {
        backgroundColor: stringToColor(name),
        verticalAlign: "middle",
      },
      size: "large",

      children: children,
    };
  } catch (error) {
    return {
      sx: {
        bgcolor: stringToColor("Zeeshan Zulfiqar"),
      },
      children: "ZZ",
    };
  }
}
