// import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Modal, Progress, Skeleton, Tooltip } from "antd";
import { collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  // uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { AppMessage, UserContext } from "../../App";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Usermenu from "../../components/UserMenu/Usermenu";
import { auth, db, storage } from "../../firebase";
import convertSecondsToHMS from "../../Util/convertSecondsToHMS";
import { useNavigate } from "react-router";
import calculateCredits from "../../Util/calculateCredits";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import getLimitForPlan from "../../Util/getLimitForSubscription";
import SkeletonInput from "antd/es/skeleton/Input";
import getStorageLimit from "../../Util/getStorageLimit";
import {
  ArrowRightCircleIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import DisplayMeesage from "../../components/DisplayMessage/DisplayMeesage";

const supportedlanguagesString = `   Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian,
Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch,
English, Estonian, Finnish, French, Galician, German, Greek, Hebrew,
Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada,
Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi,
Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian,
Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish,
Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, 
Welsh`;

const supportedLanguages = supportedlanguagesString.split(",");
const Home2 = () => {
  const [open, setopen] = useState(false);
  const [openLanguageList, setOpenLanguageList] = useState(false);
  const [translate, setTranslate] = useState(true);

  const [videoFile, setVideoFile] = useState(null);

  const [progress, setProgress] = useState(0);
  const [userSubscription, setUserSubscription] = useState("free");
  const [userlimit, setUserLimit] = useState(null);
  const [userStorageLimit, setUserStorageLimit] = useState(null);

  const [loading, setLoading] = useState(true);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState({});
  const user = useContext(UserContext);
  const messageApi = useContext(AppMessage);
  const fileInput = useRef(null);
  const videoFileRef = useRef(null);
  const uploadTask = useRef(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    setLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    auth.onAuthStateChanged(async (u) => {
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      decodedToken?.claims?.stripeRole
        ? setUserSubscription(decodedToken?.claims?.stripeRole)
        : setUserSubscription("free");
      console.log(decodedToken?.claims?.stripeRole);

      setUserLimit(getLimitForPlan(decodedToken?.claims?.stripeRole ?? "free"));
      setUserStorageLimit(
        getStorageLimit(decodedToken?.claims?.stripeRole ?? "free")
      );

      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // useEffect(() => {
  //   switch (userSubscription) {
  //     case "free":
  //       setUserLimit(null);
  //       break;

  //     case "starter":
  //       setUserLimit(180);
  //       break;

  //     case "pro":
  //       setUserLimit(420);

  //       break;
  //     case "max":
  //       setUserLimit(1000);

  //       break;

  //     default:
  //       break;
  //   }
  // }, [userSubscription, user]);
  // useEffect(() => {
  //   console.log(userlimit);
  //   console.log(userSubscription);
  // }, [userlimit]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    setVideoFile(URL.createObjectURL(file));
    setMediaLoading(true);

    setTimeout(() => {
      console.log("duration " + videoFileRef.current.duration);
      const duration = videoFileRef.current.duration;
      if (file) {
        if (file.type.includes("audio/mpeg") || file.type.includes("video")) {
        } else {
          console.log("file type");
          messageApi.open({
            type: "error",
            content: "File type must be mp3 or video",
            duration: 5,
          });
          setMediaLoading(false);
          event.target.value = null;
          return;
        }

        // if (file.size > 26214400 && file.type.includes("audio/mpeg")) {
        //   messageApi.open({
        //     type: "error",
        //     content: "Audio File must be less than 25 MB",
        //     duration: 5,
        //   });
        //   event.target.value = null;
        //   return;
        // }
        if (userSubscription === "free") {
          if (file.size > 524288000) {
            messageApi.open({
              type: "error",
              content: "The file is too big. It should be 500MB or smaller",
              duration: 5,
            });
            setMediaLoading(false);
            event.target.value = null;
            return;
          }
        } else {
          if (file.size > 1048576000) {
            messageApi.open({
              type: "error",
              content: "The file is too big. It should be 1GB or smaller",
              duration: 5,
            });
            setMediaLoading(false);
            event.target.value = null;
            return;
          }
        }
        setMediaLoading(false);

        if (duration > 3600) {
          messageApi.open({
            type: "error",
            content: "Your media should not be longer than 1 hour",
            duration: 5,
          });
          event.target.value = null;
          return;
        }
        if (
          userSubscription === "free" &&
          calculateCredits(duration) > user.trialCredits
        ) {
          messageApi.open({
            type: "error",
            content: "Not enough credits please upgrade",
            duration: 5,
          });
          console.log("not enough credits");
          navigate("/upgrade");
          event.target.value = null;
          return;
        }

        if (
          userSubscription !== "free" &&
          calculateCredits(duration) > userlimit - user.creditsConsumed
        ) {
          console.log("not enough credits");
          messageApi.open({
            type: "error",
            content: "Not enough credits",
            duration: 5,
          });
          event.target.value = null;
          return;
        }
        if (file.size > userStorageLimit - user.storageConsumed) {
          console.log("Storage Full");
          messageApi.open({
            type: "error",
            content:
              "Your Storage is Full,upgrade your plan or try deleting subtitle jobs",
            duration: 5,
          });
          event.target.value = null;
          navigate("/history");
          return;
        }
        //if all checks are ok then do this
        const jobDoc = doc(collection(db, "users", user.uid, "jobs"));

        const storageRef = ref(
          storage,
          `users/${user.uid}/media/jobs/${jobDoc.id}/${file.name}`
        );
        uploadTask.current = uploadBytesResumable(storageRef, file);

        uploadTask.current.on(
          "state_changed",
          (snapshot) => {
            let pr = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            pr = parseInt(pr);

            if (pr < 3) {
              setopen(true);
            }
            setProgress(pr);
            console.log(`Upload is ${pr}% done`);
          },
          (error) => {
            console.log(error.message);
            setMediaLoading(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 5,
            });
          },
          async () => {
            console.log("Upload complete");
            getDownloadURL(storageRef).then(async (URL) => {
              setFileInfo({
                duration: duration,
                fileType: file.type,
                fileName: file.name,
                downloadURL: URL,
                translate: translate,
                storageRef: storageRef.fullPath,
              });

              setDoc(jobDoc, {
                initialFileType: file.type,
                initialFileName: file.name,
                initalFileSize: file.size,
                mediaURL: URL,
                duration: duration,
                width: videoFileRef.current.videoWidth,
                hieght: videoFileRef.current.videoHeight,
                userId: user.id,
                originalstorageRef: storageRef.fullPath,
                isDeleted: false,
                creditsConsumedByJob: calculateCredits(duration),
                translate: translate,
                addedDate: Timestamp.now(),
                status: "initiated",
              }).then(async (snap) => {
                setopen(false);

                if (userSubscription === "free") {
                  getDoc(doc(db, "users", user.uid)).then(async (snap) => {
                    await setDoc(
                      snap.ref,
                      {
                        trialCredits:
                          snap.data().trialCredits - calculateCredits(duration),
                        storageConsumed:
                          (snap.data().storageConsumed ?? 0) + file.size,
                      },
                      { merge: true }
                    );
                  });
                } else {
                  getDoc(doc(db, "users", user.uid)).then(async (snap) => {
                    await setDoc(
                      snap.ref,
                      {
                        creditsConsumed: parseInt(
                          parseInt(snap.data().creditsConsumed) +
                            parseInt(calculateCredits(duration))
                        ),
                        storageConsumed: parseInt(
                          parseInt(snap.data().storageConsumed ?? 0) +
                            parseInt(file.size)
                        ),
                      },
                      { merge: true }
                    );
                  });
                  // await setDoc(
                  //   doc(db, "users", user.uid),
                  //   {
                  //     creditsConsumed:
                  //       user.creditsConsumed + calculateCredits(duration),
                  //     storageConsumed:
                  //       (user.storageConsumed ?? 0) + file.size,
                  //   },

                  // );
                }
                navigate(`/subtitles/${jobDoc.id}`);
              });
            });
          }
        );

        //reading file to get its duration
        // let duration = 0;
        // var reader = new FileReader();
        // reader.onload = function (event) {
        //   // var media = file.type.includes("audio")
        //   //   ? new Audio(reader.result)
        //   //   : document.createElement("video");
        //   // if (file.type.includes("video")) {
        //   //   media.src = reader.result;
        //   // }
        //   var media = new Audio(reader.result);
        //   const data = event.target.result;
        //   // const duration = data.duration;
        //   // media.onload = function () {
        //   //   console.log(media.duration);
        //   // };

        //   media.onloadedmetadata = async function () {

        //   };
        // };

        // reader.readAsDataURL(file);
        // reader.readAsArrayBuffer(file);
        // reader.r

        // setTimeout(async () => {
        // await uploadTask.current();
        // }, 4000);
      }
    }, 2000);

    // Do something with the file
  };

  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row ">
      <Modal
        open={open}
        // title="Title"
        // onOk={handleOk}
        onCancel={() => {
          if (uploadTask.current) {
            uploadTask.current.cancel();
            console.log("upload cancelled");
            fileInput.current.value = null;
          }
          setopen(false);
        }}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Return
          // </Button>,
          // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          //   Submit
          // </Button>,
          // <Button
          //   key="link"
          //   href="https://google.com"
          //   type="primary"
          //   loading={loading}
          //   onClick={handleOk}
          // >
          //   Search on Google
          // </Button>,
          <></>,
        ]}
      >
        <div className=" flex flex-col items-center gap-7">
          <div className="mt-7 text-xl font-bold">
            Uploading {fileInfo.name}
          </div>
          <Progress type="circle" percent={progress} />
        </div>
      </Modal>
      <Modal
        open={openLanguageList}
        // title="Title"
        // onOk={handleOk}
        onCancel={() => {
          // if (uploadTask.current) {
          //   uploadTask.current.cancel();
          //   console.log("upload cancelled");
          //   fileInput.current.value = null;
          // }
          setOpenLanguageList(false);
        }}
        footer={[<></>]}
      >
        <div className=" flex flex-col items-center gap-7">
          <div className="mt-7 text-xl font-bold">Supported languages</div>

          <div className="flex flex-wrap gap-2">
            {supportedLanguages.map((item) => {
              return <div className="rounded bg-gray-100 p-2"> {item}</div>;
            })}
          </div>
        </div>
      </Modal>
      <div className="flex flex-col   max-w-7xl w-100 pl-5 pr-5 sm:ml-72 lg:ml-80">
        <div className="h-8 w-full sm:h-16 "> </div>
        {!loading && (
          <div className="flex flex-col items-center gap-4 sm:items-start">
            <h1 className="text-3xl font-extrabold ">Generate Subtitles</h1>

            <div className="flex gap-2 accent-black items-center">
              {" "}
              <input
                type={"checkbox"}
                // style={{ minWidth: "32px" }}
                className="w-6 h-6"
                checked={translate}
                onChange={() => {
                  setTranslate(!translate);
                }}
              />
              <div>Translate the subtitles to English</div>
              <Tooltip title="In case you decide not to select this option, the subtitles will be generated in their original language.">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>
                </div>
              </Tooltip>
            </div>

            {/* upload */}

            <div>
              <input
                type="file"
                ref={fileInput}
                style={{ display: "none" }}
                accept="audio/mp3,video/*"
                onChange={handleFileChange}
                onLoadedMetadata={(e) => {
                  console.log(e);
                }}
              />
              <video
                controls
                style={{ display: "none" }}
                src={videoFile}
                ref={videoFileRef}
                onLoadedData={() => {
                  console.log(videoFileRef.current.duration);
                }}
              >
                {/* <source src={videoFile} /> */}
              </video>
              <MainButton
                onClick={handleButtonClick}
                loading={mediaLoading}
                // disabled={true}
              >
                <ArrowUpTrayIcon className="w-5 inline mr-2" /> Upload audio or
                video
              </MainButton>
            </div>

            {/* feature descriptions */}
            <div>
              <div
                className="cursor-pointer mt-2  underline underline-offset-2"
                onClick={() => {
                  setOpenLanguageList(true);
                }}
              >
                <ArrowRightCircleIcon className="w-5 mr-2 inline fill-lime-300" />
                Supported Languages
              </div>
              <div className="mt-2">
                <ArrowRightCircleIcon className="w-5 mr-2 inline fill-lime-300" />
                Max file size limit:{" "}
                {userSubscription === "free" ? (
                  <>
                    {" "}
                    <span className="font-bold">500MB </span>
                    <span
                      className="text-sm uppercase text-green-900 font-bold cursor-pointer"
                      onClick={() => {
                        navigate("/upgrade");
                      }}
                    >
                      {" "}
                      upgrade
                    </span>
                  </>
                ) : (
                  <span className="font-bold">1GB </span>
                )}{" "}
                {/* <Tooltip title="The app is currently in beta and only supports file sizes up to 25 MB">
                  (beta)
                </Tooltip>{" "} */}
              </div>
              <div className="mt-2">
                <ArrowRightCircleIcon className="w-5 mr-2 inline fill-lime-300" />
                Max duration{" "}
                <span className="font-bold">
                  {" "}
                  {userSubscription === "free"
                    ? `${user.trialCredits} minutes`
                    : "1 hour (60 minutes)"}{" "}
                </span>{" "}
              </div>

              <div className="mt-2">
                <div>
                  <ArrowRightCircleIcon className="w-5 inline mr-2 fill-lime-300" />
                  Supported file types: .mp3, .mp4, .mov, ogm, .wmv, .ogv, .ogm,
                  .webm, .asx, .avi, .mpeg, .m4v
                </div>
              </div>
            </div>

            <DisplayMeesage
              color={"green"}
              title={"Upcoming features"}
              subtitle={
                <ul>
                  <li>- Embed (burn) subtitles in the video file </li>
                  <li>- Edit subtitle styles </li>
                </ul>
              }
            />
          </div>
        )}
        {loading && (
          <div className="flex flex-col items-center w-full gap-4 sm:items-start">
            <h1 className="text-3xl font-extrabold ">Generate Subtitles</h1>

            <SkeletonInput size="small" active />
            <Skeleton active />

            {/* upload */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home2;
