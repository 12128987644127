import React from "react";
const getTheme = (color) => {
  switch (color) {
    case "blue":
      return {
        textColor: "border-blue-900",
        bgColor: "bg-blue-100",
      };
    case "green":
      return {
        textColor: "border-green-900",
        bgColor: "bg-green-100",
      };

    default:
      break;
  }
};
const DisplayMeesage = ({ title, subtitle, color, icon }) => {
  const themeColor = getTheme(color);
  return (
    <div
      className={`relative border-2 rounded-xl p-3 ${themeColor.textColor} ${themeColor.bgColor} max-w-md `}
    >
      {/* <span class="animate-ping absolute top-0 -left-1 w-4 h-4 rounded-full bg-green-500 "></span>
      <span class="absolute top-0 -left-1 rounded-full h-3 w-3 bg-green-500"></span> */}
      <div className={`font-bold ${themeColor.textColor} text-sm pb-1`}>
        {" "}
        {title}
      </div>
      <div className="text-sm">{subtitle}</div>
    </div>
  );
};

export default DisplayMeesage;
