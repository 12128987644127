import { BriefcaseIcon, ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { Avatar, Popover, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../App";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Usermenu from "../../components/UserMenu/Usermenu";
import "./settings.css";
// import createCheckoutSession from "../../stripe/createCheckoutSession";
import { auth } from "../../firebase";
import getLimitForPlan from "../../Util/getLimitForSubscription";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { stringAvatar } from "../../Util/stringAvatar";

const Settings = () => {
  const user = useContext(UserContext);
  const [userSubscription, setUserSubscription] = useState("free");
  const [userlimit, setUserLimit] = useState(null);

  const [loading, setLoading] = useState(true);
  const [falseImage, setFalseImage] = useState(false);
  const [subButtonLoading, setSubButtonLoading] = useState(false);
  const navigate = useNavigate("");

  useEffect(() => {
    const getRole = async () => {
      setLoading(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);

      decodedToken?.claims?.stripeRole
        ? setUserSubscription(decodedToken?.claims?.stripeRole)
        : setUserSubscription("free");
      console.log(decodedToken?.claims?.stripeRole);

      setUserLimit(getLimitForPlan(decodedToken?.claims?.stripeRole ?? "free"));
      console.log();

      setLoading(false);
    };
    getRole();
  }, [user]);

  // const functionRef = httpsCallable(
  //   functions,
  //   "ext-firestore-stripe-payments-createPortalLink"
  // );

  return (
    <div className="flex flex-col min-h-screen items-center sm:items-start gap-4 w-full sm:flex-row pb-6">
      {" "}
      {/* <Header />
      <Sidebar />
      <Usermenu /> */}
      <div className="flex flex-col items-center gap-4 sm:items-start   max-w-7xl w-100 pl-5 pr-5 w-full sm:ml-72 md:ml-80">
        <div className="h-8 w-full sm:h-16 "> </div>
        <h1 className="text-3xl font-extrabold">Settings</h1>

        {!loading && (
          <div className=" w-full flex flex-col gap-6 ">
            {/* Account */}
            <Popover placement="topLeft" content={<div>Manage Account</div>}>
              <div
                className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full cursor-pointer shadow "
                onClick={() => {
                  navigate("/account");
                }}
              >
                {falseImage ? (
                  <Avatar {...stringAvatar(user.name)}></Avatar>
                ) : (
                  <img
                    className="h-12 w-12 rounded-full"
                    src={user.photoURL}
                    onError={() => {
                      setFalseImage(true);
                    }}
                    alt={`${user.name} profile pic`}
                  />
                )}

                <div>
                  <div>{user.displayName}</div>
                  <div>{user.email}</div>
                </div>
              </div>
            </Popover>
            {/* Usage */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full shadow ">
              <ChartBarSquareIcon className="w-12" />
              <div className="w-full flex flex-col gap-3">
                <div>Usage</div>
                <div className="h-2 w-4/5  border  rounded-full  bg-white">
                  {" "}
                  <div
                    className="h-full bg-gray-900 rounded-full"
                    style={{
                      width: `${
                        ((userSubscription !== "free"
                          ? userlimit - user.creditsConsumed
                          : user.trialCredits) /
                          userlimit) *
                        100
                      }%`,
                    }}
                  ></div>{" "}
                </div>

                <div className="text-sm">
                  {userSubscription !== "free"
                    ? userlimit - user.creditsConsumed
                    : user.trialCredits}
                  {/* {userlimit -
                    (userSubscription !== "free"
                      ? user.creditsConsumed
                      : user.trialCredits)}{" "} */}{" "}
                  credits remaing out of {userlimit}
                </div>
              </div>
            </div>
            {/* Plan */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full  shadow ">
              <BriefcaseIcon className="w-12 text-green-600 flex-shrink-0" />
              <div className=" flex  gap-7 items-center flex-wrap sm:justify-between">
                <div>
                  <div>
                    You are on{" "}
                    <span className="uppercase font-bold">
                      {userSubscription}
                    </span>{" "}
                    Plan
                  </div>
                  <div className="text-sm flex items-center">
                    {userSubscription === "free"
                      ? `${userlimit} for trial`
                      : `${userlimit} per month`}
                    <Tooltip title="Each credit is equal to 1 minute of video processing">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {userSubscription !== "free" ? (
                  <div className="flex gap-4 flex-wrap">
                    <MainButton
                      onClick={() => {
                        navigate("/upgrade");
                      }}
                    >
                      Upgrade
                    </MainButton>

                    <PrimaryButton
                      loading={subButtonLoading}
                      onClick={async () => {
                        // setSubButtonLoading(true);
                        // const { data } = await functionRef({
                        //   returnUrl: window.location.origin,
                        //   locale: "auto", // Optional, defaults to "auto"
                        //   configuration: "bpc_1Mx65EKXp0WQVPyuosU6TYD1", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
                        // });
                        // window.location.assign(data.url);
                      }}
                    >
                      <a
                        href="https://billing.stripe.com/p/login/00g7uNgEV9VJ93yfYY"
                        onClick={() => {
                          setSubButtonLoading(true);
                        }}
                      >
                        {" "}
                        Manage your subscription
                      </a>
                    </PrimaryButton>
                  </div>
                ) : (
                  <>
                    {" "}
                    <MainButton
                      onClick={() => {
                        navigate("/upgrade");
                      }}
                    >
                      Upgrade
                    </MainButton>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* skeleton */}
        {loading && (
          <div className=" w-full flex flex-col gap-6 ">
            {/* Account */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full cursor-pointer shadow ">
              <Skeleton
                avatar
                active
                paragraph={{
                  rows: 1,
                }}
              />
            </div>
            {/* Usage */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full shadow ">
              <div className="w-full flex flex-col gap-3">
                <Skeleton
                  avatar={{ shape: "square" }}
                  active
                  paragraph={{
                    rows: 0,
                  }}
                />
              </div>
            </div>
            {/* Plan */}
            <div className="flex items-center gap-3 p-7 bg-gray-100 rounded-xl w-full  shadow ">
              <Skeleton
                avatar={{ shape: "square" }}
                active
                paragraph={{
                  rows: 0,
                }}
              />
              <Skeleton.Input />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
